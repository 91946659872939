const outsetaChatVisible = function() {
    return !!document.getElementsByClassName('o--Trigger--trigger').length
}
let showingOutsetaChat = false
const enforceShowingOutsetaChat = (until) => {
    if (!until) {
        if (
            window.Outseta &&
            window.Outseta.chat &&
            typeof window.Outseta.chat.show === 'function' &&
            typeof window.Outseta.chat.hide === 'function'
        ) {
            enforceShowingOutsetaChat(new Date(new Date().valueOf() + 2000))
        } else {
            setTimeout(enforceShowingOutsetaChat, 100)
        }
        return
    }
    if (showingOutsetaChat && !outsetaChatVisible()) {
        window.Outseta &&
            window.Outseta.chat &&
            typeof window.Outseta.chat.show === 'function' &&
            window.Outseta.chat.show()
    }
    if (!showingOutsetaChat && outsetaChatVisible()) {
        window.Outseta &&
            window.Outseta.chat &&
            typeof window.Outseta.chat.hide === 'function' &&
            window.Outseta.chat.hide()
    }
    if (until > new Date()) {
        setTimeout(() => {
            enforceShowingOutsetaChat(until)
        }, 100)
    }
}

window.showOutseta = function() {
    showingOutsetaChat = true
    enforceShowingOutsetaChat()
}
window.hideOutseta = function() {
    showingOutsetaChat = false
    enforceShowingOutsetaChat()
}

const loadOutseta = () => {
    window.o_options = {
        domain: 'names-and-faces.outseta.com',
        load: 'chat',
    }

    var script = document.createElement('script')
    script.type = 'text/javascript'
    script['data-options'] = 'o_options'
    script.async = true
    script.defer = true
    script.src = 'https://cdn.outseta.com/outseta.min.js'

    var first = document.getElementsByTagName('script')[0]
    first.parentNode.insertBefore(script, first)

    enforceShowingOutsetaChat()
}

loadOutseta()
