import 'core-js/stable'
import {AlertsProvider, NFThemeProvider, TooltipContext} from 'nf-ui'
import React, {useEffect} from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter} from 'react-router-dom'
import 'regenerator-runtime/runtime'
import {createGlobalStyle} from 'styled-components'
import {Token} from '~/components/TokenContext'
import '~/outseta'
import '../ui/fonts/fonts.css'
import App from './App'
import {BugSnagErrorBoundary} from './bugSnag'
import {ErrorFallback} from './components/ErrorFallback'
import {UserSettings} from './components/UserSettingsContext'
import {ProvideApollo} from './ProvideApollo'
import './segment.js'

if (!('scrollBehavior' in document.documentElement.style)) {
    import('scroll-behavior-polyfill')
}

const GlobalStyle = createGlobalStyle`
    body {
        margin: 0;
        position: relative;
        min-height: 100vh;
    }

    #app{
        isolation:isolate;
    }
`

const Root: React.FC = () => {
    useEffect(() => {
        const fromDomain = 'beta.namesandfaces.com'
        const toDomain = 'web.namesandfaces.com'

        if (window.location.host === fromDomain) {
            window.location.href = window.location.href.replace(fromDomain, toDomain)
        }
    }, [])

    useEffect(() => {
        window.analytics.ready(() => {
            const trackIp = window?.mixpanel?.get_config('ip')
            if (trackIp) window.mixpanel.set_config({ip: !trackIp})
        })
    }, [])

    return (
        <NFThemeProvider>
            <BugSnagErrorBoundary FallbackComponent={ErrorFallback}>
                <TooltipContext>
                    <AlertsProvider>
                        <Token.Provider>
                            <UserSettings.Provider>
                                <BrowserRouter>
                                    <ProvideApollo>
                                        <GlobalStyle />
                                        <App />
                                    </ProvideApollo>
                                </BrowserRouter>
                            </UserSettings.Provider>
                        </Token.Provider>
                    </AlertsProvider>
                </TooltipContext>
            </BugSnagErrorBoundary>
        </NFThemeProvider>
    )
}

if (!module.parent) {
    ReactDOM.render(<Root />, document.getElementById('app'))
}
