import {ApolloError, FetchResult, gql, MutationHookOptions, MutationTuple, useMutation} from '@apollo/client'
import {useAlert} from 'react-alert'
import {getGraphQLErrorMessage} from '~/util'
import {RequestSelfBuildUserOTP, RequestSelfBuildUserOTPVariables} from './__types__/RequestSelfBuildUserOTP'
import {CreateSelfBuildDirectory, CreateSelfBuildDirectoryVariables} from './__types__/CreateSelfBuildDirectory'

const REQUEST_SELF_BUILD_USER_OTP = gql`
    mutation RequestSelfBuildUserOTP($email: String!, $firstName: String, $lastName: String) {
        requestSelfBuildUserOTP(email: $email, firstName: $firstName, lastName: $lastName)
    }
`

const CREATE_SELF_BUILD_DIRECTORY = gql`
    mutation CreateSelfBuildDirectory($organisationName: String!, $organisationToClone: String) {
        createSelfBuildDirectory(organisationName: $organisationName, organisationToClone: $organisationToClone)
    }
`

export const useMutationRequestSelfBuildUserOtp = (
    options?: MutationHookOptions<RequestSelfBuildUserOTP, RequestSelfBuildUserOTPVariables>,
): [
    MutationTuple<RequestSelfBuildUserOTP, RequestSelfBuildUserOTPVariables>,
    (variables: {
        email: string
        firstName?: string
        lastName?: string
    }) => Promise<FetchResult<RequestSelfBuildUserOTP, Record<string, any>, Record<string, any>>>,
] => {
    const alert = useAlert()
    const mutation = useMutation<RequestSelfBuildUserOTP, RequestSelfBuildUserOTPVariables>(
        REQUEST_SELF_BUILD_USER_OTP,
        options,
    )
    const callMutation = async (variables: {email: string; firstName?: string; lastName?: string}) => {
        try {
            const result = await mutation[0]({variables})
            return result
        } catch (error) {
            alert.error(getGraphQLErrorMessage(error as ApolloError))
            throw error
        }
    }
    return [mutation, callMutation]
}

export const useMutationCreateSelfBuildDirectory = (
    options?: MutationHookOptions<CreateSelfBuildDirectory, CreateSelfBuildDirectoryVariables>,
): [
    MutationTuple<CreateSelfBuildDirectory, CreateSelfBuildDirectoryVariables>,
    (variables: {
        organisationName: string
        organisationToClone?: string
    }) => Promise<FetchResult<CreateSelfBuildDirectory, Record<string, any>, Record<string, any>>>,
] => {
    const alert = useAlert()
    const mutation = useMutation<CreateSelfBuildDirectory, CreateSelfBuildDirectoryVariables>(
        CREATE_SELF_BUILD_DIRECTORY,
        options,
    )
    const callMutation = async (variables: {organisationName: string; organisationToClone?: string}) => {
        try {
            const result = await mutation[0]({variables})
            return result
        } catch (error) {
            alert.error(getGraphQLErrorMessage(error as ApolloError))
            throw error
        }
    }
    return [mutation, callMutation]
}
