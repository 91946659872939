import {ApolloError, FetchResult, gql, MutationTuple, StoreObject, useMutation, useQuery} from '@apollo/client'
import {useAlert} from 'react-alert'
import {DataEditValueUpsert, DataEditFieldValue} from '~/globalTypes'
import {getGraphQLErrorMessage} from '~/util'
import {DataEditGetValues, DataEditGetValuesVariables} from './__types__/DataEditGetValues'
import {DataEditUpdateValues, DataEditUpdateValuesVariables} from './__types__/DataEditUpdateValues'
import {
    DataEditAddNewProfiles,
    DataEditAddNewProfiles_addNewProfiles,
    DataEditAddNewProfilesVariables,
} from './__types__/DataEditAddNewProfiles'
import {DataEditDeleteProfiles, DataEditDeleteProfilesVariables} from './__types__/DataEditDeleteProfiles'
import {ReadFieldFunction} from '@apollo/client/cache/core/types/common'
import {
    DataEditHiddenValuesByProfile,
    DataEditHiddenValuesByProfileVariables,
} from './__types__/DataEditHiddenValuesByProfile'
import {DataEditHiddenValues, DataEditHiddenValuesVariables} from './__types__/DataEditHiddenValues'
import {
    convertDataEditValuesByProfileIdStr,
    convertDataEditValuesIndexed,
    DataEditValue,
    useOrganisationContext,
} from '~/components/OrganisationContext'
import {difference, groupBy, uniq, uniqBy} from 'lodash'
import {
    UpdateProfileValues,
    UpdateProfileValues_updateProfile_fields,
    UpdateProfileValuesVariables,
} from './__types__/UpdateProfileValues'
import {DeleteProfile, DeleteProfile_deleteProfile, DeleteProfileVariables} from './__types__/DeleteProfile'

export const DATA_EDIT_GET_VALUES = gql`
    query DataEditGetValues($organisationIdStr: String!) {
        dataEditValues(organisationIdStr: $organisationIdStr, hideHideableFields: true) {
            fieldOrParentCategoryIdStr
            profileIdStr
            value
            valueIdStr
            organisationIdStr
        }
    }
`

const DATA_EDIT_HIDDEN_VALUES_BY_PROFILE = gql`
    query DataEditHiddenValuesByProfile($organisationIdStr: String!, $profileIdStr: String!) {
        dataEditValues(organisationIdStr: $organisationIdStr, profileIdStr: $profileIdStr, hiddenValuesOnly: true) {
            fieldOrParentCategoryIdStr
            profileIdStr
            value
            valueIdStr
            organisationIdStr
        }
    }
`

const DATA_EDIT_HIDDEN_VALUES = gql`
    query DataEditHiddenValues($organisationIdStr: String!) {
        dataEditValues(organisationIdStr: $organisationIdStr, hiddenValuesOnly: true) {
            fieldOrParentCategoryIdStr
            profileIdStr
            value
            valueIdStr
            organisationIdStr
        }
    }
`

const DATA_EDIT_UPDATE_VALUES = gql`
    mutation DataEditUpdateValues(
        $organisationIdStr: String!
        $dataEditValues: [DataEditValueUpsert!]!
        $removeMissingValues: Boolean
    ) {
        updateDataEditValues(
            organisationIdStr: $organisationIdStr
            dataEditValues: $dataEditValues
            removeMissingValues: $removeMissingValues
        ) {
            fieldOrParentCategoryIdStr
            profileIdStr
            value
            valueIdStr
            organisationIdStr
        }
    }
`

const DATA_EDIT_ADD_NEW_PROFILES = gql`
    mutation DataEditAddNewProfiles($organisationIdStr: String!, $newProfiles: [[DataEditFieldValue!]!]!) {
        addNewProfiles(organisationIdStr: $organisationIdStr, newProfiles: $newProfiles) {
            fieldOrParentCategoryIdStr
            profileIdStr
            value
            valueIdStr
            organisationIdStr
        }
    }
`

const DATA_EDIT_DELETE_PROFILES = gql`
    mutation DataEditDeleteProfiles($organisationIdStr: String!, $profileIdStrs: [String!]!) {
        deleteProfiles(organisationIdStr: $organisationIdStr, profileIdStrs: $profileIdStrs)
    }
`

export const UPDATE_PROFILE = gql`
    mutation UpdateProfileValues($dataEditValues: [DataEditValueUpsert!]!, $removeMissingValues: Boolean) {
        updateProfile(dataEditValues: $dataEditValues, removeMissingValues: $removeMissingValues) {
            values {
                fieldOrParentCategoryIdStr
                profileIdStr
                value
                valueIdStr
            }
            fields {
                idStr
                organisationIdStr
                type
                subType
                name
                availableValues
                availableValueIdStrs
                baseFields {
                    idStr
                    organisationIdStr
                    type
                    subType
                    name
                    availableValues
                    availableValueIdStrs
                }
            }
        }
    }
`

export const DELETE_PROFILE = gql`
    mutation DeleteProfile($profileIdStr: String!) {
        deleteProfile(profileIdStr: $profileIdStr) {
            remainingUserOrganisations
        }
    }
`

export interface SortField {
    idStr: string
    direction?: 'asc' | 'desc'
}

export const useQueryDataEditGetValues = (organisationIdStr: string) =>
    useQuery<DataEditGetValues, DataEditGetValuesVariables>(DATA_EDIT_GET_VALUES, {
        variables: {
            organisationIdStr,
        },
        fetchPolicy: 'no-cache',
    })

export const useQueryDataEditHiddenValuesByProfile = (organisationIdStr: string, profileIdStr: string) =>
    useQuery<DataEditHiddenValuesByProfile, DataEditHiddenValuesByProfileVariables>(
        DATA_EDIT_HIDDEN_VALUES_BY_PROFILE,
        {
            variables: {
                organisationIdStr,
                profileIdStr,
            },
            fetchPolicy: 'no-cache',
        },
    )

export const useQueryDataEditHiddenValues = (organisationIdStr: string) =>
    useQuery<DataEditHiddenValues, DataEditHiddenValuesVariables>(DATA_EDIT_HIDDEN_VALUES, {
        variables: {
            organisationIdStr,
        },
        fetchPolicy: 'no-cache',
    })

export const mergeDataEditValues = (
    visible: DataEditValue[],
    hidden: DataEditValue[],
    updated: DataEditValue[],
    removeMissingValues: boolean,
): {visible: DataEditValue[]; hidden: DataEditValue[]} => {
    const visibleIndexed = convertDataEditValuesIndexed(visible)
    const hiddenIndexed = convertDataEditValuesIndexed(hidden)
    const updatedIndexed = convertDataEditValuesIndexed(updated)

    Object.keys(updatedIndexed).forEach((key) => {
        if (removeMissingValues) {
            if (hiddenIndexed[key]) {
                hiddenIndexed[key] = updatedIndexed[key]
            } else {
                visibleIndexed[key] = updatedIndexed[key]
            }
        } else {
            if (hiddenIndexed[key]) {
                hiddenIndexed[key] = uniqBy([...hiddenIndexed[key], ...updatedIndexed[key]], (value) => value.value)
            } else {
                visibleIndexed[key] = uniqBy([...visibleIndexed[key], ...updatedIndexed[key]], (value) => value.value)
            }
        }
    })

    return {
        visible: Object.values(visibleIndexed).flatMap((values) => values),
        hidden: Object.values(hiddenIndexed).flatMap((values) => values),
    }
}

export const cachePolicyDataEditGetValues = {
    read: (existing: StoreObject[] = [], {readField}: {readField: ReadFieldFunction}) => {
        return existing.filter((e) => readField<string>('value', e) !== null)
    },
    merge: (existing: StoreObject[] = [], incoming: StoreObject[], {readField}: {readField: ReadFieldFunction}) => {
        const isSameObject = (a: StoreObject, b: StoreObject) =>
            readField<string>('fieldOrParentCategoryIdStr', a) === readField<string>('fieldOrParentCategoryIdStr', b) &&
            readField<string>('profileIdStr', a) === readField<string>('profileIdStr', b)
        return [
            ...existing.map(
                (existingObject) =>
                    incoming.find((incomingObject) => isSameObject(existingObject, incomingObject)) || existingObject,
            ),
            ...incoming.filter(
                (incomingObject) => !existing.some((existingObject) => isSameObject(existingObject, incomingObject)),
            ),
        ].filter((v) => v.value !== null)
    },
}

export const useMutationDataEditUpdateValues = (
    organisationIdStr: string,
): [
    MutationTuple<DataEditUpdateValues, DataEditUpdateValuesVariables>,
    (variables: {dataEditValues: DataEditValueUpsert[]; removeMissingValues: boolean}) => Promise<DataEditValue[]>,
] => {
    const alert = useAlert()
    const mutation = useMutation<DataEditUpdateValues, DataEditUpdateValuesVariables>(DATA_EDIT_UPDATE_VALUES, {})
    const callMutation = async (variables: {dataEditValues: DataEditValueUpsert[]; removeMissingValues: boolean}) => {
        try {
            return (
                await Promise.all(
                    Object.values(
                        groupBy(
                            variables.dataEditValues.map((value, index) => ({value, group: Math.floor(index / 500)})),
                            (pair) => pair.group,
                        ),
                    ).map(
                        async (group) =>
                            (
                                await mutation[0]({
                                    variables: {
                                        organisationIdStr,
                                        dataEditValues: group.map((pair) => ({...pair.value, organisationIdStr})),
                                        removeMissingValues: variables.removeMissingValues,
                                    },
                                })
                            ).data?.updateDataEditValues || [],
                    ),
                )
            ).flatMap((array) => array)
        } catch (error) {
            alert.error(getGraphQLErrorMessage(error as ApolloError))
            throw error
        }
    }
    return [mutation, callMutation]
}

export const useMutationDataEditAddNewProfiles = (
    organisationIdStr: string,
): [
    MutationTuple<DataEditAddNewProfiles, DataEditAddNewProfilesVariables>,
    (variables: {newProfiles: DataEditFieldValue[][]}) => Promise<DataEditAddNewProfiles_addNewProfiles[]>,
] => {
    const alert = useAlert()
    const mutation = useMutation<DataEditAddNewProfiles, DataEditAddNewProfilesVariables>(
        DATA_EDIT_ADD_NEW_PROFILES,
        {},
    )
    const callMutation = async (variables: {newProfiles: DataEditFieldValue[][]}) => {
        try {
            return (
                await Promise.all(
                    Object.values(
                        groupBy(
                            variables.newProfiles.map((value, index) => ({value, group: Math.floor(index / 100)})),
                            (pair) => pair.group,
                        ),
                    ).map(
                        async (group) =>
                            (
                                await mutation[0]({
                                    variables: {organisationIdStr, newProfiles: group.map((pair) => pair.value)},
                                })
                            ).data?.addNewProfiles || [],
                    ),
                )
            ).flatMap((result) => result)
        } catch (error) {
            alert.error(getGraphQLErrorMessage(error as ApolloError))
            throw error
        }
    }
    return [mutation, callMutation]
}

export const useMutationDataEditDeleteProfiles = (
    organisationIdStr: string,
): [
    MutationTuple<DataEditDeleteProfiles, DataEditDeleteProfilesVariables>,
    (variables: {
        profileIdStrs: string[]
    }) => Promise<FetchResult<DataEditDeleteProfiles, Record<string, any>, Record<string, any>>>,
] => {
    const alert = useAlert()
    const mutation = useMutation<DataEditDeleteProfiles, DataEditDeleteProfilesVariables>(DATA_EDIT_DELETE_PROFILES, {})
    const [dataEditValues, setDataEditValues] = useOrganisationContext.useDataEditValues()
    const [, setDataEditValuesByProfileIdStr] = useOrganisationContext.useDataEditValuesByProfileIdStr()
    const callMutation = async (variables: {profileIdStrs: string[]}) => {
        try {
            const result = await mutation[0]({variables: {organisationIdStr, ...variables}})
            const merged = dataEditValues.filter((value) => !variables.profileIdStrs.includes(value.profileIdStr))
            setDataEditValues(merged)
            setDataEditValuesByProfileIdStr(convertDataEditValuesByProfileIdStr(merged))
            return result
        } catch (error) {
            alert.error(getGraphQLErrorMessage(error as ApolloError))
            throw error
        }
    }
    return [mutation, callMutation]
}

export const useMutationUpdateProfile = (): [
    MutationTuple<UpdateProfileValues, UpdateProfileValuesVariables>,
    (variables: {
        dataEditValues: DataEditValueUpsert[]
        removeMissingValues: boolean
    }) => Promise<UpdateProfileValues_updateProfile_fields[]>,
] => {
    const alert = useAlert()
    const mutation = useMutation<UpdateProfileValues, UpdateProfileValuesVariables>(UPDATE_PROFILE, {})
    const callMutation = async (variables: {dataEditValues: DataEditValueUpsert[]; removeMissingValues: boolean}) => {
        try {
            const results = await Promise.all(
                Object.values(
                    groupBy(
                        variables.dataEditValues.map((value, index) => ({value, group: Math.floor(index / 500)})),
                        (pair) => pair.group,
                    ),
                ).map(
                    async (group) =>
                        await mutation[0]({
                            variables: {
                                dataEditValues: group.map((pair) => pair.value),
                                removeMissingValues: variables.removeMissingValues,
                            },
                        }),
                ),
            )
            return results.reduce<UpdateProfileValues_updateProfile_fields[]>((previous, result) => {
                const data = result.data?.updateProfile?.fields || []
                return uniq([...previous, ...data].map((field) => field.idStr)).map((idStr) => {
                    const p = previous.find((field) => field.idStr === idStr)
                    const r = data.find((field) => field.idStr === idStr)
                    return {
                        __typename: 'DataEditField',
                        idStr,
                        organisationIdStr: r?.organisationIdStr || p!.organisationIdStr,
                        type: r?.type || p!.type,
                        subType: r?.subType || p?.subType || null,
                        name: r?.name || p!.name,
                        availableValues: [
                            ...(p?.availableValues || []),
                            ...difference(r?.availableValues || [], p?.availableValues || []),
                        ],
                        availableValueIdStrs: [
                            ...(p?.availableValueIdStrs || []),
                            ...difference(r?.availableValueIdStrs || [], p?.availableValueIdStrs || []),
                        ],
                        baseFields: r?.baseFields || p?.baseFields || null,
                    }
                })
            }, [])
        } catch (error) {
            alert.error(getGraphQLErrorMessage(error as ApolloError))
            throw error
        }
    }
    return [mutation, callMutation]
}

export const useMutationDeleteProfile = (): [
    MutationTuple<DeleteProfile, DeleteProfileVariables>,
    (variables: {profileIdStr: string}) => Promise<DeleteProfile_deleteProfile | undefined>,
] => {
    const alert = useAlert()
    const mutation = useMutation<DeleteProfile, DeleteProfileVariables>(DELETE_PROFILE, {})
    const [dataEditValues, setDataEditValues] = useOrganisationContext.useDataEditValues()
    const [, setDataEditValuesByProfileIdStr] = useOrganisationContext.useDataEditValuesByProfileIdStr()
    const callMutation = async (variables: {
        profileIdStr: string
    }): Promise<DeleteProfile_deleteProfile | undefined> => {
        try {
            const result = await mutation[0]({variables})
            const merged = dataEditValues.filter((value) => variables.profileIdStr !== value.profileIdStr)
            setDataEditValues(merged)
            setDataEditValuesByProfileIdStr(convertDataEditValuesByProfileIdStr(merged))
            return result.data?.deleteProfile
        } catch (error) {
            alert.error(getGraphQLErrorMessage(error as ApolloError))
            throw error
        }
    }
    return [mutation, callMutation]
}
