import * as React from 'react'
const SvgHelpCenter = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgHelpCenter(
    props,
    svgRef,
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            ref={svgRef}
            {...props}
            fill="none"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <circle cx="12" cy="12" r="10" />
            <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
            <path d="M12 17h.01" />
        </svg>
    )
})
export default SvgHelpCenter
