import {Icon, IconContainer, Typography} from 'nf-ui'
import SvgHelpCenter from 'nf-ui/Icons/HelpCenter'
import React from 'react'
import styled from 'styled-components'
import {CurrentOrganisation} from '../CurrentOrganisationContext'
import {RoleType} from '~/globalTypes'

export const Container = styled.a`
    display: flex;
    margin-bottom: 16px;
    margin-left: 16px;
    align-items: center;
    text-decoration: none;
    ${IconContainer} {
        margin-right: 16px;
    }
`

export const HelpCenter = () => {
    const {currentOrganisation} = CurrentOrganisation.useContainer()

    if (
        !currentOrganisation?.currentUserRoles?.includes(RoleType.Manage) ||
        !currentOrganisation.appFeatures?.selfBuild
    )
        return null

    return (
        <Container href="https://names-and-faces.outseta.com/support/kb" target="_blank">
            <Icon icon={SvgHelpCenter} />
            <Typography.Paragraph bottomMargin={false}>Help Center</Typography.Paragraph>
        </Container>
    )
}
