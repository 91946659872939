import React, {useState} from 'react'
import {isAndroid, isIOS} from 'react-device-detect'
import {FormLayout} from '~/components/Primitives/Forms'
import {Button} from 'nf-ui'
import {Column, Row} from '~/components/Primitives/Layout'
import {PageLoading} from '~/components/PageLoading'

export const SuggestMobile: React.FC<{dismissed: boolean; setDismissed: (value: boolean) => void}> = ({
    dismissed,
    setDismissed,
    children,
}) => {
    const [imagesLoaded, setImagesLoaded] = useState(false)

    const showAndroid = isAndroid
    const showIOS = isIOS

    return dismissed || window.innerWidth > 809 || (!showAndroid && !showIOS) ? (
        <>{children}</>
    ) : (
        <div>
            {!imagesLoaded ? <PageLoading></PageLoading> : null}
            <div style={imagesLoaded ? {} : {display: 'none'}}>
                <FormLayout
                    heading="Download the app"
                    subHeading={[`We have detected a mobile device. Choose an option below.`]}
                    progress={0}
                >
                    <Column>
                        {showIOS ? (
                            <a href="https://apps.apple.com/us/app/names-faces/id949875464">
                                <img
                                    src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                                    alt="Download on the App Store"
                                    style={{width: '177px'}}
                                    onLoad={() => setImagesLoaded(true)}
                                ></img>
                            </a>
                        ) : null}
                        {showAndroid ? (
                            <a href="http://play.google.com/store/apps/details?id=za.co.polymorph.namesfaces">
                                <img
                                    width="250"
                                    src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
                                    alt="Get it on Google Play"
                                    onLoad={() => setImagesLoaded(true)}
                                ></img>
                            </a>
                        ) : null}
                        <Row height="50px"></Row>
                        <Button onClick={() => setDismissed(true)}>Continue with browser</Button>
                    </Column>
                </FormLayout>
            </div>
        </div>
    )
}
