import React, {FC, useEffect} from 'react'
import styled from 'styled-components'
import SvgCheck from 'nf-ui/Icons/Check'
import {Spinner} from 'nf-ui'
import {orderBy} from 'lodash'

const MenuEntry = styled.div`
    display: flex;
    justify-content: space-between;
    font-family: 'halyard-display';
    font-size: 16px;
    font-weight: 300;
    padding: 0px 0px;
    background-color: white;
    border-top: 1px solid #d5dbdf;
    border-width: 1px 0px;
    border-radius: 0px;
    > * {
        padding: 16px 8px;
    }
    &.selected {
        font-weight: 500;
    }
    > :not(input) {
        cursor: pointer;
    }
    .edit {
        padding: 18px 8px 16px;
    }
    .check {
        margin: -4px 0px -2px;
        padding: 6px 6px 0px;
    }
    &:hover {
        background-color: #eee;
    }
    &.can-initiate-edit:not(:hover) {
        padding: 0px 32px 0px 0px;
    }
    &:not(:hover),
    &:not(.can-initiate-edit) {
        > .edit {
            display: none;
        }
    }
    &.sticky-top {
        position: sticky;
        top: 0px;
    }
    &.sticky-bottom {
        position: sticky;
        bottom: 0px;
        padding: 3px 0px;
        color: #3c8eff;
        font-weight: 500;
    }
`

const SpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    > * {
        height: 20px;
    }
`

export interface PickReportsToProps {
    availableValues: Record<string, string>
    selectedValues: string[]
    searchValue: string
    mutating: {mutation: string; col?: number; row?: number} | undefined
    setMutating: (value: {mutation: string; col?: number; row?: number} | undefined) => void
    pickValue: (value?: {value: string; valueIdStr: string}) => Promise<void>
}

export const PickReportsTo: FC<PickReportsToProps> = (props) => {
    const filteredTuples = orderBy(
        Object.entries(props.availableValues).filter(([, value]) =>
            props.searchValue.split(' ').every((part) => value.toLowerCase().includes(part.toLowerCase())),
        ),
        ([, value]) => value,
    )

    const initialScrollItemRef = React.createRef<HTMLDivElement>()

    useEffect(() => {
        initialScrollItemRef?.current?.scrollIntoView({block: 'center'})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div style={{maxHeight: '38vh', minHeight: '43px', minWidth: '200px', overflow: 'auto'}}>
            {filteredTuples.map(([profileIdStr, value], index) => {
                const isSelected = props.selectedValues.includes(profileIdStr)
                return (
                    <MenuEntry
                        className={`${isSelected ? 'selected' : ''}`}
                        key={index}
                        ref={isSelected ? initialScrollItemRef : undefined}
                    >
                        {props.mutating?.mutation !== `pick-value-${profileIdStr}` ? (
                            <span
                                onClick={async () => {
                                    if (isSelected) return
                                    props.setMutating({mutation: `pick-value-${profileIdStr}`})
                                    await props.pickValue(isSelected ? undefined : {valueIdStr: profileIdStr, value})
                                    props.setMutating(undefined)
                                }}
                            >
                                <span>{value}</span>
                                {isSelected && <SvgCheck className="check"></SvgCheck>}
                                <span></span>
                            </span>
                        ) : (
                            <SpinnerContainer>
                                <Spinner></Spinner>
                            </SpinnerContainer>
                        )}
                    </MenuEntry>
                )
            })}
            {props.selectedValues.length && !props.searchValue ? (
                <MenuEntry className="sticky-bottom">
                    {props.mutating?.mutation !== 'clear-value' ? (
                        <span
                            onClick={async () => {
                                props.setMutating({mutation: 'clear-value'})
                                await props.pickValue(undefined)
                                props.setMutating(undefined)
                            }}
                        >
                            Clear Selection
                        </span>
                    ) : (
                        <SpinnerContainer>
                            <Spinner></Spinner>
                        </SpinnerContainer>
                    )}
                </MenuEntry>
            ) : null}
        </div>
    )
}
